import http from '@/request/http'

const selectData = {
    // 获取实时表格数据
    getTabData(pageNum, pageSize) {
        return http.post('selectAllUser', { pageNum, pageSize })
    },

    // 获取历史表格数据
    getHisTabData(
        pageNum,
        pageSize,
        apartmentComplexName,
        building,
        unit,
        room,
        startTime,
        endTime,
        qianFeiStatus
    ) {
        return http.post('selectUserByAddressHistory', {
            pageNum,
            pageSize,
            apartmentComplexName,
            building,
            unit,
            room,
            startTime,
            endTime,
            qianFeiStatus
        })
    },

    // 搜索表格数据
    searchTab(model) {
        return http.post('selectUserByAddress', model)
    },

    // 操作日志
    getLogs(pageNum, pageSize, operator, info, startTime, endTime) {
        return http.post('selectOperate', {
            pageNum,
            pageSize,
            operator,
            info,
            startTime,
            endTime
        })
    }
}

export default selectData
