import Cookies from 'js-cookie'

const state = {
    token: Cookies.get('token') || '',
    username: Cookies.get('username') || ''
}

const mutations = {
    // 设置token
    SET_USERINFO: (state, userInfo) => {
        state.token = userInfo.token
        state.username = userInfo.username
        Cookies.set('token', userInfo.token)
        Cookies.set('username', userInfo.username)
    },

    // 清除token
    REMOVE_TOKEN: state => {
        state.token = ''
        state.username = ''
        Cookies.remove('token')
        Cookies.remove('username')
    }
}

const actions = {
    /**
     * 清除token
     */
    clearToken: ({ commit }) => {
        return new Promise(resolve => {
            commit('REMOVE_TOKEN')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
