<template>
    <div class="login-container h-100 po-relative">
        <!-- main -->
        <div class="login-main zlogin-img po-relative">
            <div class="zboxs">
                <div class="zlogos zpull-left">
                    <img src="../../assets/images/dllogo.png" alt="" />
                    <p>{{ $Config.company.logo }}</p>
                </div>
                <div class="zforms zpull-left">
                    <el-form
                        ref="logoFormRef"
                        :rules="loginModelRules"
                        :model="loginModel"
                        class="login-form po-absolute"
                    >
                        <el-form-item prop="username">
                            <!-- 输入框 -->
                            <el-input
                                type="username"
                                v-model="loginModel.username"
                                placeholder="请输入用户名"
                            >
                                <i
                                    slot="prefix"
                                    class="iconfont icon-account"
                                ></i>
                            </el-input>
                        </el-form-item>

                        <el-form-item prop="password">
                            <el-input
                                type="password"
                                v-model="loginModel.password"
                                placeholder="请输入密码"
                            >
                                <i
                                    slot="prefix"
                                    class="iconfont icon-password"
                                ></i>
                            </el-input>
                        </el-form-item>

                        <el-button
                            type="primary"
                            class="w-100 qr-code mb-1"
                            @click="handleUserLoginClick"
                        >
                            登录
                        </el-button>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import validRules from '@/utils/validRules'
export default {
    name: 'Login',
    data() {
        // 验证用户名
        let validUserName = (rule, value, callback) => {
            const res = validRules('name', value)
            // console.log(res.bla)
            if (!res.bla) {
                return callback(new Error(res.msg + ' ：' + res.title))
            } else {
                callback()
            }
        }

        // 验证密码
        let validPassword = (rule, value, callback) => {
            if (value.length < 6) {
                return callback(new Error('密码至少6位'))
            } else {
                callback()
            }
        }
        return {
            // 登录表单验证
            loginModelRules: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur'
                    },
                    {
                        validator: validUserName,
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    },
                    {
                        validator: validPassword,
                        trigger: 'blur'
                    }
                ]
            },

            // 登录表单
            loginModel: {},

            // 跳转地址
            redirectAdress: ''
        }
    },
    // 回车登录
    created() {
        let that = this
        document.onkeypress = function(e) {
            var keycode = document.all ? event.keyCode : e.which
            if (keycode == 13) {
                that.handleUserLoginClick() // 登录方法名
                return false
            }
        }
    },
    methods: {
        // 用户登录
        async handleUserLoginClick() {
            const res = await this.$api.user.login(
                this.loginModel.username,
                this.loginModel.password
            )

            if (res.code !== 200) {
                this.$message.error(res.msg)
            } else {
                this.$message.success(res.msg)
            }

            // 持久化token
            this.$store.commit('user/SET_USERINFO', {
                token: res.data.token,
                username: res.data.username
            })

            // 定义跳转地址
            const redirectAdress = this.redirectAdress || '/'

            // 跳转
            this.$router.push(redirectAdress)
        }
    }
}
</script>

<style>
.zlogin-img {
    position: relative;
    height: 100%;
    color: #fff;
    background: #0b0f1e;
    background-image: url(../../assets/images/loginbg.png);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 140px 0;
}

.zboxs {
    width: 960px;
    height: 322px;
    background: linear-gradient(
        305deg,
        rgba(24, 29, 49, 0.65),
        rgba(19, 23, 43, 0.95) 100%,
        rgba(19, 23, 43, 0.87) 0
    );
    box-shadow: 0 30px 50px 0 rgb(4 27 48 / 4%);
    border-radius: 161px 6px 6px 161px;
    border: 1px solid hsla(180, 5%, 96%, 0.21);
    padding: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border-top-left-radius: 161px;
    border-bottom-left-radius: 161px;
}

.zlogos {
    width: 60%;
    height: 100%;
    position: relative;
}

.zlogos p {
    text-align: center;
    position: absolute;
    font-size: 24px;
    top: 150px;
    left: 62px;
}

.zlogos img {
    position: absolute;
    width: 80%;
    height: 80%;
    top: -15px;
    left: 0;
}

.zforms {
    width: 40%;
    height: 100%;
    padding: 15px 0 15px 60px;
    box-sizing: border-box;
    border-left: 1px solid #dcdddd;
}

.zpull-left {
    float: left;
}
.login-container .form-enter {
    margin: 0px auto 0;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    background: #1890ff !important;
}
.zforms .el-button {
    border: none !important;
    padding: 8px !important;
    color: #fff !important;
}
</style>
