import http from '../http'

const system = {
    // 注册风盘型号
    registerData(model) {
        return http.post('fp_Register', model)
    },

    // 获取注册信息
    //getTabData() {
    //    return http.post('selectFpType')
    //},
    getTabData(model) {
        return http.post('selectPrice', model)
    },

    // 显示当前单价
    getPice() {
        return http.post('currentPrice')
    },

    // 切换单价
    getPrice(/*season, dangWei,*/ newPrice, operator) {
        return http.post('revicePrice', {
            /* season, dangWei, */ newPrice,
            operator
        })
    },

    // 显示当前
    getSeason(model) {
        return http.post('nowSeason', model)
    },

    // 切换季节
    getSeasons(setSeason, operator) {
        return http.post('switchSeason2', { setSeason, operator })
    },

    upPrice(model) {
        return http.post('revisePrice', model)
    }
}
export default system
