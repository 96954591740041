import http from '@/request/http'
const user = {
    // 用户登录
    login(username, password) {
        return http.post('user/login', {
            username,
            password
        })
    }

    // 获取所有用户
    // getUserAll() {
    //     return http.post('/user/selectUsername', {})
    // }
}

export default user
