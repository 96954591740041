import http from '@/request/http'
const device = {
    // 发送参数
    getDev(operator, deviceList, roomList, jiFei, zhouQI, kaiGuan) {
        return http.post('reviseAll', {
            operator,
            deviceList,
            roomList,
            jiFei,
            zhouQI,
            kaiGuan
        })
    },

    // 修改设备号
    editDevId(FpId, operator, newId) {
        return http.post('reviseUserId', { FpId, operator, newId })
    },
    // 时间清零
    timeClear(operator, deviceList, roomList) {
        return http.post('qingLing', { operator, deviceList, roomList })
    },

    // 结算
    countPay(operator, startTime, jieSuanDay, publicCost) {
        return http.post('jieSuanSeason', {
            operator,
            startTime,
            jieSuanDay,
            publicCost
        })
    }
}

export default device
