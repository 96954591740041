import http from '@/request/http'
const pay = {
    // 充值
    getPay(deviceId, addMoney, type, operator) {
        return http.post('sensorChongZhi', {
            deviceId,
            addMoney,
            type,
            operator
        })
    },

    // 获取充值信息
    getPayMsg(
        pageNum,
        pageSize,
        apartmentComplexName,
        building,
        unit,
        room,
        startTime,
        endTime,
        qianFeiStatus
    ) {
        return http.post('selectAllChongZhiHistory', {
            pageNum,
            pageSize,
            apartmentComplexName,
            building,
            unit,
            room,
            startTime,
            endTime,
            qianFeiStatus
        })
    }
}

export default pay
