import user from './api/user'
import global from './api/global'
import device from './api/device'
import realtime from './api/realtime'
import pay from './api/pay'
import selectData from './api/selectData'
import survey from './api/survey'
import system from './api/system'
export default {
    user,
    global,
    device,
    realtime,
    pay,
    selectData,
    survey,
    system
}
