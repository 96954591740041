import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// style
import './assets/style/index.scss'

// config
import Config from './config'
Vue.prototype.$Config = Config

// element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)

// moment
import moment from 'moment'
Vue.prototype.$moment = moment

// axios
import api from './request'
Vue.prototype.$api = api

// import axios from 'axios'
// Vue.prototype.$axios = axios

// echarts
import * as echarts from 'echarts'
import 'echarts-liquidfill'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
