<template>
    <div class="home-aside">
        <el-row class="home-navbar d-flex ai-center jc-between w-100">
            <!-- 公司名称 -->
            <el-col :span="5">
                <span class="font-18 company-name">
                    {{ $Config.company.logo }}
                </span>
            </el-col>

            <!-- 菜单导航栏 -->
            <el-col :span="16">
                <el-menu
                    mode="horizontal"
                    router
                    unique-opened
                    background-color="#fff"
                    text-color="#000"
                    active-text-color="#409EFF"
                    :default-active="$route.path"
                    :default-openeds="['light']"
                >
                    <el-menu-item
                        v-for="(navItem, navIndex) in navbar"
                        :key="navIndex"
                        :index="('/' + navItem.path) | toBias"
                    >
                        <i
                            style="font-size:20px"
                            :style="{ color: navItem.color }"
                            :class="['iconfont', navItem.icon]"
                        ></i>
                        {{ navItem.name }}
                    </el-menu-item>
                </el-menu>
            </el-col>

            <!-- 全屏显示 -->
            <el-col :span="4" class="d-flex jc-end ai-center">
                <div class="home-header">
                    <div class="d-flex jc-end ai-center">
                        <div class="px-1" @click="handleScreenFullIconClick">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="全屏显示"
                                placement="bottom"
                            >
                                <i class="iconfont icon-screen-full cu-p"></i
                            ></el-tooltip>
                        </div>

                        <div class="user-info d-flex px-1">
                            <div class="mr-1">
                                <i class="iconfont icon-account"></i>
                            </div>
                            <div class="user-name woh">
                                <span>{{ $store.getters.username }}</span>
                            </div>
                        </div>

                        <div class="px-2">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="退出登录"
                                placement="bottom"
                            >
                                <i
                                    class="iconfont icon-logout cu-p"
                                    @click="handleLogoutClick"
                                ></i>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { toBias } from '@/utils/tool'
import screenfull from 'screenfull'
export default {
    name: 'HomeHeader',
    data() {
        return {
            navbar: this.$Config.navbar,
            screenfullFlag: false
        }
    },
    filters: {
        toBias
    },

    methods: {
        // 全屏显示
        handleScreenFullIconClick() {
            if (!screenfull.isEnabled) {
                this.$message.error(
                    '您的浏览器无法使用全屏功能，请更换谷歌浏览器或者请手动点击F11按钮全屏展示！'
                )
                return false
            }

            screenfull.toggle()
            if (screenfull.isFullscreen) {
                this.screenfullFlag = false
            } else {
                this.screenfullFlag = true
            }
        },

        // 退出登录
        handleLogoutClick() {
            this.$store.commit('user/REMOVE_TOKEN')
            this.$router.push('/login')
        }
    }
}
</script>

<style lang="scss">
.el-menu {
    border: none !important;
    .el-menu-item {
        padding: 0 14px !important;
        // 子菜单悬停颜色
        &:hover {
            background: #dbf2fd !important;
        }
    }

    .el-submenu__title {
        // 菜单悬停颜色
        &:hover {
            background: #dbf2fd !important;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.16);
        }
        is-active {
            background: red !important;
        }
    }
}

.home-navbar {
    background-color: #fff;
    box-shadow: 0px 0px 10px #ccc;
    font-weight: 700;
    color: #000;
    .company-name {
        padding: 0 10px;
    }
}
.home-logo {
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}
</style>
