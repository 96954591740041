export default {
    http: {
        baseURL: 'http://81.70.59.8:8082/centralair/', //服务器地址
        //baseURL: 'http://192.168.0.12:8084/centralair/', //本机地址
        voucher: false,
        timeout: 120000
    },
    company: {
        // logo: '武陟懿品-中央空调计费系统',
        // name: 'ZYIS-H 中央空调',
        logo: '中央空调能耗监控及计费系统V3.0',
        name: '中央空调能耗监控及计费系统V3.0',
        copyright: '河南众源系统工程有限公司',
        address: '河南省郑州市高新区国槐街8号火炬大厦A座1602',
        tele: '0371-67896222',
        icp: '沪ICP备05031232号-34',
        gwa: '沪公网安备31011402007268号'
    },
    map: {
        ak: 'HE5BlZinsvqCISbVv2ABE1DdDWPKgtGW'
    },
    navbar: [
        {
            icon: 'icon-gaikuang2',
            name: '运行概况',
            path: 'page_survey'
        },
        {
            icon: 'icon-shebei1',
            name: '设备管理',
            path: 'page_device'
        },
        {
            icon: 'icon-shiduanfenxi',
            name: '实时数据',
            path: 'page_realtime'
        },
        {
            icon: 'icon-shishi',
            name: '历史数据',
            path: 'page_history'
        },
        {
            icon: 'icon-chongzhi',
            name: '充值信息',
            path: 'page_pay'
        },
        {
            icon: 'icon-xitongshezhi',
            name: '系统设置',
            path: 'page_system'
        },
        {
            icon: 'icon-icon-test',
            name: '操作日志',
            path: 'page_logs'
        },
        {
            icon: 'icon-daochubiaoge',
            name: '报表导出',
            path: 'page_exportdata'
        }
        // {
        //     icon: 'icon-lishijilu',
        //     name: '历史数据',
        //     path: 'locking_history'
        // },
        // {
        //     icon: 'icon-yunhangnengxiaofenxi',
        //     name: '运行统计',
        //     path: 'locking_fun'
        // },
        // {
        //     icon: 'icon-_warning',
        //     name: '报警信息',
        //     path: 'locking_alarm'
        // },
        // {
        //     icon: 'icon-caozuorizhi',
        //     name: '操作日志',
        //     path: 'locking_log'
        // }
    ]
}
