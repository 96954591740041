import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login'
import Layout from '../views/layout'
import $store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: {
            name: 'PageSurvey'
        },
        children: [
            {
                path: '/page/survey',
                name: 'PageSurvey',
                component: () => import('@/views/page/survey/index.vue')
            },
            {
                path: '/page/device',
                name: 'PageDevice',
                component: () => import('@/views/page/device/index.vue')
            },
            {
                path: '/page/realtime',
                name: 'PageRealtime',
                component: () => import('@/views/page/realtime/index.vue')
            },
            {
                path: '/page/history',
                name: 'PageHistory',
                component: () => import('@/views/page/history/index.vue')
            },
            {
                path: '/page/pay',
                name: 'PagePay',
                component: () => import('@/views/page/pay/index.vue')
            },
            {
                path: '/page/system',
                name: 'PageSystem',
                component: () => import('@/views/page/system/index.vue')
            },
            {
                path: '/page/logs',
                name: 'PageLogs',
                component: () => import('@/views/page/logs/index.vue')
            },
            {
                path: '/page/exportdata',
                name: 'PageExportdata',
                component: () => import('@/views/page/exportdata/index.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const token = $store.getters.token
    if (to.path !== '/login' && !token) {
        next(`/login?redirect=${to.path}`)
    } else {
        next()
    }
})

export default router
