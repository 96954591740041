import http from '../http'

const survey = {
    // 总用量
    getLineBar(startTime) {
        return http.post('pastDailyDangLiang', { startTime })
    },
    getCard() {
        return http.post('generalSituation')
    },

    getLine(startTime) {
        return http.post('pastDailyDangWeiXinZeng', { startTime })
    }
}

export default survey
