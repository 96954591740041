import axios from 'axios'
import { Message } from 'element-ui'
import $store from '@/store'
import Cookies from 'js-cookie'
import $Config from '@/config'

const http = axios.create({
    baseURL: $Config.http.baseURL,
    withCredentials: $Config.http.voucher,
    timeout: $Config.http.timeout
})

// 请求拦截
http.interceptors.request.use(
    config => {
        const token = $store.getters.token
            ? $store.getters.token
            : Cookies.get('token')
        if (token) {
            config.headers['token'] = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截
// 需根据后台返回数据的格式修改
http.interceptors.response.use(
    response => {
        // console.log('响应拦截-后台返回数据->', response)
        const res = response.data
        // token: 非法/过期
        if (res.code === '401' || res.code === 400) {
            Message({
                message: 'token错误，请重新登录',
                type: 'error',
                duration: 5 * 1000
            })
            // 清空token 刷新页面->跳转到登录页
            $store.dispatch('user/clearToken').then(() => {
                location.reload()
            })
        } else {
            return res
        }
    },
    error => {
        console.log('响应拦截错误--->' + error)
        Message({
            message: '响应拦截错误--->：服务端出错,请联系管理员',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default http
