import http from '../http'
// import qs from 'qs'

const global = {
    /**
     * 获取小区
     */
    getXq() {
        return http.post('selectApartmentComplexList')
    },

    /**
     * 获取楼栋
     */
    getBuild(model) {
        return http.post('selectBuildingList', model)
    },

    /**
     * 获取单元
     */
    getCellNO(model) {
        return http.post('selectUnitList', model)
    },

    /**
     * 获取户号
     */
    getHouseNO(model) {
        return http.post('selectRoomList', model)
    }

    /**
     * 搜索小区
     * @param {String} xqName
     */
    // getGivenXq(xqName) {
    //     return http.post(
    //         '/j-yhinfo/selectTree',
    //         qs.stringify({
    //             xqName
    //         })
    //     )
    // },
}

export default global
