import $moment from 'moment'

/**
 * 函数防抖
 * @param {时间} delay
 * @param {函数} callback
 */
export function debounce(delay, callback) {
    let lastTime
    return function() {
        clearTimeout(lastTime)
        const [that, args] = [this, arguments]
        lastTime = setTimeout(() => {
            callback.apply(that, args)
        }, delay)
    }
}

/**
 * 随机数
 * @param {最小值} minNum
 * @param {最大值} maxNum
 */
export function randomExtend(minNum, maxNum) {
    if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
    } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    }
}

/**
 * 设置class
 * @param {*} element
 * @param {*} className
 */
export function toggleClass(element, className) {
    if (!element || !className) {
        return
    }
    element.className = className
}

/**
 * 随机颜色
 */
export function randomColor() {
    return (
        '#' +
        ('00000' + ((Math.random() * 0x1000000) << 0).toString(16)).slice(-6)
    )
}

/**
 * 下环线转反斜杠
 */
export function toBias(str) {
    return str.replace(/_/g, '/')
}

/**
 * 随机时间
 */

export function randomTime(timeNum, type) {
    const otime = timeNum ? timeNum : 24 * 60 * 60 * 1000
    const nowDate = new Date()
    const ztNum = nowDate.setTime(nowDate.getTime() - otime)

    const dsRd = randomExtend(1, 23)
    const ds = dsRd < 10 ? '0' + dsRd : dsRd

    const dfRd = randomExtend(1, 23)
    const df = dfRd < 10 ? '0' + dfRd : dfRd

    const dmRd = randomExtend(0, 59)
    const dm = dmRd < 10 ? '0' + dmRd : dmRd

    let zt

    switch (type) {
        case 0:
            zt = $moment(ztNum).format('YYYY-MM-DD')
            break

        case 1:
            zt =
                $moment(ztNum).format('YYYY-MM-DD') +
                ' ' +
                ds +
                ':' +
                df +
                ':' +
                dm
            break

        default:
            break
    }

    return zt
}
