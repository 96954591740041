<template>
    <div class="home-container h-100">
        <home-aside />
        <div class="home-main">
            <div class="home-view">
                <router-view />
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
import HomeAside from './HomeAside'
export default {
    name: 'Layout',
    components: {
        HomeAside
    }
}
</script>

<style lang="scss" scoped>
.home-container {
    .home-main {
        height: calc(100% - 80px);
        .home-view {
            // height: calc(100% - 60px);
            height: 100%;
        }
    }
}
</style>
